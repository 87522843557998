//  --------------- login status ---------------
.content {
    width: 100vw;
    height: 100vh;
    background-image: url('./assets/background.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .other_content {
    width: 100vw;
    height: 100vh;
    background-size: 100% 70%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .other_logink {
    width: 25vw;
    height: 55vh;
    display: flex;
    align-items: center;
    margin: 0 65vw;
    background: #fff;
    box-shadow: 0 0 4px #ccc;
    border-radius: 10px;
    position: relative;
    top: 20vh;
    justify-content: center;
  
  
    .logink_right {
      display: inline-block;
      .logink_title {
        font-size: 30px;
        color: #3f81c1;
        font-style: normal;
        font-weight: bold;
        width: 20vw;
        margin: 0 auto;
        font-family: 'Microsoft YaHei', sans-serif;
        font-weight: bold;
      }
      .longin_search {
        width: 20vw;
        max-width: 20vw;
        margin-top: 30px;
        height: 48px;
        background: #eef6ff;
        border-radius: 25px;
        font-size: 14px;
        text-indent: 70px;
        outline: none;
      }
      .login_btn {
        width: 20vw;
        height: 48px;
        line-height: 48px;
        text-align: center;
        font-size: 18px;
        color: #fff;
        margin: 0px 5px;
        background-color: #3f81c1;
        border-radius: 50px;
        cursor: pointer;
        float: left;
    }
    }
  }
  .other_logink_min {
    width: 80vw;
    height: 55vh;
    display: flex;
    align-items: center;
    margin: 0 10vw;
    background: #fff;
    box-shadow: 0 0 4px #ccc;
    border-radius: 10px;
    position: relative;
    top: 20vh;
    justify-content: center;
  
  
    .logink_right {
      display: inline-block;
      .logink_title {
        font-size: 30px;
        color: #3f81c1;
        font-style: normal;
        font-weight: bold;
        width: 76vw;
        margin: 0 auto;
        font-family: 'Microsoft YaHei', sans-serif;
        font-weight: bold;
      }
      .longin_search {
        width: 76vw;
        max-width: 76vw;
        margin-top: 30px;
        height: 48px;
        background: #eef6ff;
        border-radius: 25px;
        font-size: 14px;
        text-indent: 70px;
        outline: none;
      }
      .login_btn {
        width: 76vw;
        height: 48px;
        line-height: 48px;
        text-align: center;
        font-size: 18px;
        color: #fff;
        margin: 0px 5px;
        background-color: #3f81c1;
        border-radius: 50px;
        cursor: pointer;
        float: left;
    }
    }
  }
  .alerts {
    position: fixed;
    top: 30px;
    width: auto;
    align-self: center;
    margin: 0 auto;
    z-index: 9999;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  .logink_min {
    width: 80vw;
    height: 75vh;
    display: flex;
    align-items: center;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 0 4px #ccc;
    border-radius: 10px;
    position: relative;
    top: 5%;
    flex-direction: column;
    justify-content: space-evenly;
  
    .logink_left_img_min {
      .left_img_min {
        width: 60vw;
        margin-top: 10px;
      }
    }
  
    .logink_right_min {
      display: inline-block;
      width: 70vw;
    }
  }
  
  .logink {
    width: 960px;
    height: 460px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 0 4px #ccc;
    border-radius: 10px;
    position: relative;
    top: 16%;
  
    .logink_left_img {
      margin: 0 75px 0 65px;
      display: inline-block;
      width: 392px;
      height: 242px;
  
      .left_img {
        width: 392px;
        height: 242px;
      }
    }
  
    .logink_right {
      display: inline-block;
    }
  }
  
  .logink_title {
    font-size: 30px;
    color: #3f81c1;
    font-style: normal;
    font-weight: bold;
    width: 336px;
    margin: 0 auto;
    font-family: 'Microsoft YaHei', sans-serif;
        font-weight: bold;
  }
  .logink_title_min  {
    font-size: 30px;
    color: #3f81c1;
    font-style: normal;
    font-weight: bold;
    width: 77vw;
    margin: 0 auto;
    font-family: 'Microsoft YaHei', sans-serif;
        font-weight: bold;
  }
  
  .login_con {
    position: relative;
  }
  .login_con_min {
    position: relative;
  }
  .longin_search {
    width: 336px;
    max-width: 336px;
    margin-top: 30px;
    height: 48px;
    background: #eef6ff;
    border-radius: 25px;
    font-size: 14px;
    text-indent: 70px;
    outline: none;
  }
  
  .longin_search_min {
    width: 70vw;
    margin-top: 1vh;
    height: 5vh;
    background: #eef6ff;
    font-size: 14px;
    text-indent: 10vw;
    outline: none;
  
  }
  
  .longin_search::-webkit-input-placeholder {
    /* placeholder颜色  */
    color: #999999;
    /* placeholder字体大小  */
    font-size: 14px;
    /* placeholder位置  */
    text-align: left;
  }
  
  .longin_search_min::-webkit-input-placeholder {
    /* placeholder颜色  */
    color: #999999;
    /* placeholder字体大小  */
    font-size: 14px;
    /* placeholder位置  */
    text-align: left;
  }
  .longin_search_img {
    position: absolute;
    top: 46px;
    left: 34px;
  }
  .longin_search_img_min {
    position: absolute;
    top: 2vh;
    left: 3vw;
  }
  .login_btn {
    width: 336px;
    /*  width: 110px; */
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    margin: 0px 5px;
    background-color: #3f81c1;
    border-radius: 50px;
    cursor: pointer;
    float: left;
  }
  .login_btn_min {
    width: 70vw;
    height: 5vh;
    line-height: 5vh;
    text-align: center;
    font-size: 18px;
    color: #fff;
    margin: 0px 5px;
    background-color: #3f81c1;
    border-radius: 50px;
    cursor: pointer;
    float: left;
  
  }
  .wangji {
    width: 306px;
    margin: 0 auto;
    text-align: right;
    color: #5473e8;
    font-size: 16px;
  }
  .login_btn_all {
    // display: flex;
    // flex-direction: column;
    // justify-content: space-evenly;
    // align-items: center;
    // flex-wrap: nowrap;
    // height: 172px;
  }
  .login_btn_all_min {
    margin-top: 4vh;
  }
//  --------------- login end ------------------